<template>
  <modal :name="id" :width="width" :height="height" :adaptive="true">
    <div class="m-5 text-center">
      <h3>{{ title }}</h3>
      <small>{{ description }}</small>
      <textarea v-if="descriptionShow" placeholder="Bir neden giriniz" id="desc" class="form-control"></textarea>
      <div class="row">
        <div class="col-6">
          <input
            class="btn btn-success mt-5 w-100"
            type="button"
            @click="check"
            :value="yes"
          />
        </div>
        <div class="col-6">
          <input
            class="btn btn-danger mt-5 w-100"
            type="button"
            @click="close"
            :value="no"
          />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  props: ["id","title", "description","descriptionShow", "yes", "no","width","height"],
  created() {},
  data() {
    return {
    };
  },
  methods: {
    check() {
      if(this.descriptionShow==true && document.getElementById('desc').value!=''){
        this.$emit("response", [true,document.getElementById('desc').value]);
      } else {
        if(this.descriptionShow==true && document.getElementById('desc').value==''){
           this.$vToastify.warning(
            "Açıklama girilmedi!",
            "Uyarı!"
          );
        } else {
          this.$emit("response", true);
        }
      }
    },
    close() {
      this.$modal.hide(this.id);
    },
  },
};
</script>