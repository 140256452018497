<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Randevular</h3>
        
      </div>
      <div class="card-body">
        <div class="row">
          <div class="card" v-if="(list.length>0 && list[0].own==1 && $store.state.user_type == 'EXPERT')">
            
            <div class="card-body">
              <div class="form-group">
                <label>Danışan Takip Formu Tanımla</label>
                <select class="form-control" @change="formChange">
                  <option disabled selected>Form Seçiniz</option>
                  <option v-for="item,i in formList" :value="item.id" :key="i" :selected="(selectedFormId==item.id?true:false)">{{item.title}}</option>
                </select>
              </div>
              <div class="row" v-for="a,i in formData" :key="i">
                <h4>{{a.title}}</h4>
                <div class="form-group" v-for="(b,t) in a.subList" :key="t">
                  <label>{{b.title}}</label>
                  <textarea v-model="b.response" :id="('text'+b.id)" class="form-control">
                  </textarea>
                </div>
              </div>
              <input type="button" class="btn btn-primary float-right" @click="saveResponse" value="Kaydet"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card" v-for="(item, i) in list" :key="i">
            <div class="card-header">
              <div class="row w-100">
                <div class="col-sm-12 col-md-12 col-xl-12">
                  <h2><b>#</b>{{ item.id }}</h2>
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Tam Adı:</b>{{ item.fullname }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Terapist:</b>{{ item.expert_name }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Randevu Tarihi:</b>{{ item.date }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Telefon:</b>{{ item.phone }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Randevu Saati:</b>{{ JSON.parse(item.hour).begin }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Randevu Durumu:</b
                  >{{
                    item.status == 1
                      ? "ONAYLANDI"
                      : item.status == 0
                      ? "ONAY BEKLİYOR"
                      : "İPTAL EDİLDİ"
                  }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6" v-if="item.payment">
                  <b>Ödeme Yöntemi:</b>{{ item.payment.title }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Oluşturma Tarihi:</b>{{ item.created_at }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Talep Edilen Hizmet:</b>{{ item.experience_name }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Hizmet Yöntemi:</b>
                  <template v-if="item.selected_methods == 1">
                    Yüz Yüze Görüşme
                  </template>
                  <template v-if="item.selected_methods == 2">
                    Evde Görüşme
                  </template>
                  <template v-if="item.selected_methods == 3">
                    Online Görüşme
                  </template>
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Randevu Durumu:</b>
                  <template
                    v-if="
                      item.is_appointment_done == 0 && item.time_expire == 0
                    "
                  >
                    Henüz Gerçekleşmedi
                  </template>
                  <template
                    v-if="
                      item.time_expire == 1 && item.is_appointment_done == 0
                    "
                  >
                    İşlem Bekliyor
                  </template>
                  <template
                    v-if="
                      item.is_appointment_done == 1 && item.time_expire == 1
                    "
                  >
                    Randevu Başarılı bir şekilde gerçekleşti
                  </template>
                  <template v-if="item.is_appointment_done == 2">
                    Randevu gerçekleşemedi
                  </template>
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Açıklama:</b>
                  {{ item.description }}
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6">
                  <b>Randevu Türü:</b>
                  {{ item.pre_meeting==0?'NORMAL':'ÖN RANDEVU' }}
                </div>
                <template
                  v-if="item.selected_methods == 3 && item.online_button == 1"
                >
                  <div class="col-sm-12 col-md-6 col-xl-6 mt-2 mb-2">
                    <router-link
                      :to="'/chat-room/' + item.id"
                      class="btn btn-secondary"
                    >
                      <i class="fa fa-video-camera" style="font-size: 50px"></i
                      ><br />
                      Görüntülü Görüşmeye Başla
                    </router-link>
                  </div>
                </template>
              </div>
            </div>
            <div class="card-body">
              <div class="row" v-if="item.selected_methods == 2">
                <div class="col-12"><b>Adres:</b></div>
                <div class="col-12">{{ item.address }}</div>
              </div>
              <div class="row" v-if="item.selected_methods == 2">
                <div class="col-6"><b>il:</b><br />{{ item.city }}</div>
                <div class="col-6"><b>ilçe:</b><br />{{ item.province }}</div>
              </div>
              <div class="row" v-if="item.description != null">
                <div class="col-12">
                  <b>Açıklama:</b><br />{{ item.description }}
                </div>
              </div>
              <div class="row mt-4">
                <div
                  class="col-12"
                  v-if="
                    (item.status == 0 && item.time_expire == 0) ||
                    (item.own == 1 && item.status == 1 && item.time_expire == 0)
                  "
                >
                  <label>Yeni Randevu Saati Seçin</label>
                  <select
                    @click="getLink(item.user_id)"
                    :id="'changeappointmentselect' + item.itemId"
                    class="form-select mb-5"
                    aria-label="Default select example"
                  >
                    <option selected>Saat Seçin</option>
                    <optgroup
                      v-for="(dates, i) in hourList.list"
                      :key="i"
                      :label="hourList.dates[i]"
                    >
                      <option
                        v-for="(test, a) in dates.list"
                        :key="'option' + a"
                        :value="test.id"
                        :disabled="test.available == 0 ? true : false"
                        :selected="
                          dates.date == item.date &&
                          JSON.parse(test.hour).begin ==
                            JSON.parse(item.hour).begin
                            ? true
                            : false
                        "
                      >
                        {{ JSON.parse(test.hour).begin }}
                      </option>
                    </optgroup>
                  </select>
                  <button
                    v-if="
                      (item.status == 0 || item.status == 1) &&
                      item.time_expire == 0
                    "
                    class="btn btn-warning w-100 mb-5"
                    @click="chanceAppointmentDialogBox(item.itemId)"
                  >
                    Randevu Tarihini Değiştir
                  </button>
                </div>
                <div
                  class="col-sm-12 col-md-6 col-xl-6"
                  v-if="
                    item.status == 1 &&
                    item.is_appointment_done == 0 &&
                    item.payment_approved_button_show == 1 &&
                    item.own == 1
                  "
                >
                  <button
                    class="btn btn-success w-100"
                    @click="appointmentApprovedDialogBox(item.itemId)"
                  >
                    Randevu Gerçekleşti
                  </button>
                </div>
                <div
                  class="col-sm-12 col-md-6 col-xl-6"
                  v-if="
                    item.status == 1 &&
                    item.is_appointment_done == 0 &&
                    item.payment_approved_button_show == 1 &&
                    item.own == 1
                  "
                >
                  <button
                    class="btn btn-danger w-100"
                    @click="appointmentCancelDialogBox(item.itemId)"
                  >
                    Randevu Gerçekleşmedi
                  </button>
                </div>
                <div
                  class="col-sm-12 col-md-6 col-xl-6"
                  v-if="
                    item.status == 0 &&
                    $store.state.user_type == 'EXPERT' &&
                    item.user_id == $store.state.user_id
                  "
                >
                  <button
                    class="btn btn-success w-100"
                    @click="approvedDialogBox(item.itemId)"
                  >
                    Onayla
                  </button>
                </div>
                <div class="col-sm-12 col-md-6 col-xl-6" v-if="
                      (item.status == 0 || item.status == 1) &&
                      item.time_expire == 0
                    ">
                  <button
                    class="btn btn-danger w-100"
                    @click="cancelDialogBox(item.itemId)"
                  >
                    İptal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-block text-center">
          <Pagination
            :currentpage="currentpage"
            :pagesize="pagesize"
            :preload="'/profile/appointments/page/'"
          />
        </div>
      </div>
    </div>
    <DialogBox
      title="Onaylamak İstediğinize emin misiniz?"
      yes="Onayla"
      no="İptal"
      id="approveddialog"
      width="300"
      height="210"
      @response="approvedDialogResult($event)"
    />
    <DialogBox
      title="Randevuyu Tamamlamak İstediğinize emin misiniz?"
      yes="Onayla"
      no="İptal"
      id="appointmentApprovedDialog"
      width="350"
      height="180"
      @response="appointmentApprovedDialogResult($event)"
    />
    <DialogBox
      title="Randevuyu iptal etmek istediğinize emin misiniz?"
      yes="Onayla"
      no="İptal"
      id="appointmentCancelDialog"
      width="350"
      height="250"
      :descriptionShow="true"
      @response="appointmentCancelDialogResult($event)"
    />

    <DialogBox
      id="canceldialog"
      title="Randevuyu iptal etmek İstediğinize emin misiniz?"
      yes="Onayla"
      width="400"
      height="260"
      no="İptal"
      :descriptionShow="true"
      @response="cancelDialogResult($event)"
    />
    <DialogBox
      id="changeappointment"
      title="Randevu tarihini değiştirmek İstediğinize emin misiniz?"
      width="400"
      height="240"
      yes="Onayla"
      no="İptal"
      @response="changeApprovedDialogResult($event)"
    />
  </div>
</template>
<script>
import DialogBox from "../../../../components/DialogBox.vue";
import Page from "../../../Page.vue";
import Pagination from "../../../../components/Pagination.vue";
export default {
  created() {
    document.title = "Randevularım";
    this.list = [];
    this.isLoading = true;
    this.getAppoinementList();
  },
  data() {
    return {
      hourList: [],
      list: [],
      selectedId: 0,
      currentpage: 1,
      page: 1,
      perpage: 10,
      totalsize: 0,
      pagesize: 0,
      isLoading: true,
      selectedStatus: 0,
      selectedFormId:0,
      onlineData: [],
      formList:[],
      formData:[]
    };
  },
  methods: {
    formChange(e){
      let selectedValue = e.target.value;
      this.$store.dispatch("getClientForm", {id:selectedValue}).then((value) => {
        this.formData = value.list;
      });
    },
    saveResponse(){
      let info = {
        id: this.$route.params.member_id,
        data:JSON.stringify(this.formData)
      };
      this.$store.dispatch("setClientForm", info).then((value) => {
        this.onlineData = value.data;
        this.$vToastify.success(
              "Kayıt Başarılı",
              "Başarılı!"
            );
      });
    },
    openVideoCalling(item) {
      let info = {
        id: item.id,
      };
      this.$store.dispatch("videoCallingGetInfo", info).then((value) => {
        this.onlineData = value.data;
      });
    },
    getAppoinementList() {
      this.list = [];
      if (this.$route.params.page) {
        this.currentpage = this.$route.params.page;
      }
      let info = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        selectedStatus: this.selectedStatus,
        member_id:this.$route.params.member_id,
        expert:this.$route.params.expert_id
      };
      this.$store.dispatch("myAppointmentDetailList", info).then((value) => {
        this.list = value.list;
        this.formList=value.formList;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.isLoading = false;
        this.formData=value.formData;
        this.selectedFormId=value.formId;
      });
    },
    getLink(user_id) {
      let appointmentsGet = {
        user_id: user_id,
      };
      this.$store.dispatch("getAppointments", appointmentsGet).then((value) => {
        this.hourList = value;
      });
    },
    statusChange(i) {
      this.selectedStatus = i;
      this.getAppoinementList();
    },
    searchAppointment() {
      this.list = [];
      this.isLoading = true;
      let info = {
        begin: 0,
        perpage: this.perpage,
        searchValue: document.getElementById("searchAppointment").value,
        member_id:this.$route.params.member_id
      };
      this.$store.dispatch("myAppointmentDetailList", info).then((value) => {
        this.list = value.list;
        this.formList=value.formList;
        this.formData=value.formData;
        this.selectedFormId=value.formId;
      });
    },
    chanceAppointmentDialogBox(id) {
      this.selectedId = id;
      this.$modal.show("changeappointment");
    },
    appointmentApprovedDialogBox(id) {
      this.selectedId = id;
      this.$modal.show("appointmentApprovedDialog");
    },
    appointmentApprovedDialogResult() {
      let info = {
        id: this.selectedId,
      };
      this.$store.dispatch("myAppointmentDone", info).then((value) => {
        this.$modal.hide("appointmentApprovedDialog");
        this.getAppoinementList();
      });
    },
    appointmentCancelDialogBox(id) {
      this.selectedId = id;
      this.$modal.show("appointmentCancelDialog");
    },
    appointmentCancelDialogResult(e) {
      let info = {
        id: this.selectedId,
        description: e[1],
      };
      this.$store
        .dispatch("myAppointmentAppointmentDone", info)
        .then((value) => {
          this.$modal.hide("appointmentCancelDialog");
          this.getAppoinementList();
        });
    },
    approvedDialogBox(id) {
      this.selectedId = id;
      this.$modal.show("approveddialog");
    },
    approvedDialogResult() {
      let info = {
        id: this.selectedId,
      };
      this.$store.dispatch("myAppointmentApproved", info).then((value) => {
        if (value.type == "error") {
          if (value.message == "ERRxATO") {
            this.$vToastify.warning(
              "Randevu süresi geçtiği için işlem yapılamaz",
              "Uyarı!"
            );
          }
        }
        this.$modal.hide("approveddialog");
        this.getAppoinementList();
      });
    },
    changeApprovedDialogResult: function (e) {
      let selectedHour = document.getElementById(
        "changeappointmentselect" + this.selectedId
      ).value;
      let info = {
        id: this.selectedId,
        selectedHour: selectedHour,
      };
      this.$store.dispatch("myAppointmentChanged", info).then((value) => {
        if (value.type == "error") {
          if (value.message == "ERRxAPNCHNG") {
            this.$vToastify.warning(
              "Son 24 saat içinde bulunan randevuya işlem yapamazsınız!",
              "Uyarı!"
            );
          }
        } else {
          this.$modal.hide("changeappointment");
          this.getAppoinementList();
        }
      });
    },
    cancelDialogBox(id) {
      this.selectedId = id;
      this.$modal.show("canceldialog");
    },
    cancelDialogResult: function (e) {
      let info = {
        id: this.selectedId,
        description: e[1],
      };
      this.$store.dispatch("myAppointmentCancel", info).then((value) => {
        if (value.type == "error") {
          if (value.message == "ERRxAPPNCANCL") {
            this.$vToastify.warning(
              value.text,
              "Uyarı!"
            );
          }
        } else {
          this.$modal.hide("canceldialog");
          this.getAppoinementList();
        }
      });
    },
  },
  watch: {
    "$route.params.page": function () {
      this.currentpage = this.$route.params.page;
      this.getAppoinementList();
    },
  },
  components: { DialogBox, Page, Pagination },
  mounted() {},
};
</script>